import React, { useState } from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, NavbarText } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import { useMsal } from '@azure/msal-react';

export const NavMenu = () => {
    const [collapsed, setCollapsed] = useState(true);
    const toggleNavbar = () => {
        setCollapsed(!collapsed);
    };

    const { accounts } = useMsal();
    const currentUser = accounts[0];
    console.log(currentUser);

    return (
        <header>
            <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" container light>
                <NavbarBrand tag={Link} to="/">CIAM_React_Dotnet_App</NavbarBrand>
                <NavbarToggler onClick={toggleNavbar} className="mr-2" />
                <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!collapsed} navbar>
                    <ul className="navbar-nav flex-grow">
                        <NavItem>
                            <NavLink tag={Link} className="text-dark" to="/">Home</NavLink>
                        </NavItem>
                         <NavItem>
                            <NavLink tag={Link} className="text-dark" to="/customers">Customers Table</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink tag={Link} className="text-dark" to="/customer/add">Add Customer</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink tag={Link} className="text-dark" to="/subscriptions">Subscription Table</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink tag={Link} className="text-dark" to="/subscription/add">Add Subscription</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink tag={Link} className="text-dark" to="/subscription/import">Import Subscriptions</NavLink>
                        </NavItem>
                    </ul>
                </Collapse>
                <NavbarText title={currentUser.username}>{currentUser.name} </NavbarText>
            </Navbar>
        </header>
    );

}