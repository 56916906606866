import GetCustomer from "./components/GetCustomer";
import GetAllCustomers from "./components/GetAllCustomers";
import AddCustomers from "./components/AddCustomers";
import GetSubscriptions from "./components/GetSubscriptions";
import AddSubscription from "./components/AddSubscription";
import { Home } from "./components/Home";
import ImportCustomers from "./components/ImportCustomers";
import ImportSubscriptions from "./components/ImportSubscriptions";
import GetSubscription from "./components/GetSubscription";
const AppRoutes = [
    {
        index: true,
        element: <Home />
    },
    {
        path: '/customers',
        element: <GetAllCustomers />
    },
    {
        path: '/customer/:customerId',
        element: <GetCustomer />
    },
    {
        path: '/customer/add',
        element: <AddCustomers />
    },
    {
        path: '/subscriptions',
        element: <GetSubscriptions />
    },
    {
        path: '/subscription/:id',
        element: <GetSubscription />
    },
    {
        path: '/subscription/add',
        element: <AddSubscription />
    },
    {
        path: '/customer/import',
        element: <ImportCustomers />
    },
    {
        path: '/subscription/import',
        element: <ImportSubscriptions />
    }
];

export default AppRoutes;
