import axios from "axios";
import Loader from "./Loader"
import React, { Component } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Toast } from 'bootstrap';
import { isNullOrWhiteSpace } from "../Helpers";
export class ViewSubscriptions extends Component {
    static displayName = ViewSubscriptions.name;
    constructor(props) {
        super(props);
        let { customer } = props;
        this.state = {
            customer: customer,
            subscriptions: [],
            loading: true,
            errorMessage: "",
            error: true,
        };
    }

    static getDerivedStateFromProps(props, state) {
        // get state from provided props
        return { customer: props.customer }
    }

    // list of subscriptions
    async getSubscriptionList() {
        await axios.get(`customersubscription/${this.state.customer.customerId}`)
            .then(data => {
                // handle successful response data
                this.setState({
                    details: false,
                    subscriptions: data.data,
                    loading: false,
                    error: false
                })
            })
            .catch(error => {
                // handle any error that occurred during the POST
                // extract proper error message
                let errorMessage = "Unknown error occurred.";
                if (error) {
                    console.error(error);
                    if (error.response) {
                        // handle successful response data
                        errorMessage = error.response.data.message ? error.response.data.message : error.message;
                    }
                    else {
                        errorMessage = error.message;
                    }
                }
                // set state
                this.setState({
                    errorMessage: errorMessage,
                    loading: false,
                    error: true
                })
            });
    }

    async generateAccessToken(subscription) {
        this.setState({ accessTokenLoading: true });
       
        await axios.post(`subscription/${subscription.customerId}/accesstoken`, {
            sqlInstance: subscription.eAutomate.sqlInstance,
            customerName: ""
        })
            .then(data => {
                // handle successful response data
                var subs = this.state.subscriptions.map((sub, i) => {
                    if (sub.id === subscription.id) {
                        sub.accessToken = data.data;
                        return sub;
                    }
                    else {
                        return sub;
                    }
                });
                
                this.setState({
                    subscriptions: subs,
                    accessTokenLoading: false,
                    error: false
                })
            })
            .catch(error => {
                // handle any error that occurred during the POST
                // extract proper error message
                let errorMessage = "Unknown error occurred.";
                if (error) {
                    console.error(error);
                    if (error.response) {
                        // handle successful response data
                        errorMessage = error.response.data.message ? error.response.data.message : error.message;
                    }
                    else {
                        errorMessage = error.message;
                    }
                }
                // set state
                this.setState({
                    errorMessage: errorMessage,
                    accessTokenLoading: false,
                    error: true
                })
            });
    }
    toast() {
        // look for toast items in DOM(we only have one)
        var toastElList = [].slice.call(document.querySelectorAll('.toast'))
        // enumerate list of toast items
        let toastList = toastElList.map(function (toastEl) {
            return new Toast(toastEl)
        })
        // make toasts show
        toastList.forEach(toast => toast.show()) 
    }
    componentDidMount() {
        // load initial subscription list
        this.getSubscriptionList();
    }
    componentDidUpdate(prevProps) {
        // only call this if the props have changed, ie, the customer was changed in the parent(AddSubscription component)
        if (this.props.customer.customerId !== prevProps.customer.customerId) {
            this.getSubscriptionList();
        }
    }

    renderCustomerSubscriptions() {
        if (this.state.loading) {
            return <div className="card">
                <div className="card-header">
                    <h6>{this.state.customer.accountName}'s Subscriptions</h6>
                </div>
                <div className="card-body">
                        <Loader message="Loading customer subscriptions please wait.."></Loader>
                </div>
            </div>;
        }
        else {
            if (this.state.error) {
                return <div className="card">
                    <div className="card-header">
                        <h6>{this.state.customer.accountName}'s Subscriptions</h6>
                    </div>
                    <div className="card-body">
                        <div className="alert alert-danger" role="alert">
                            {this.state.errorMessage}
                        </div>
                    </div>
                </div>;
            }
            else {
                return (
                    <div className="card">
                        {this.state.subscriptions.map(subscription =>
                            <div key={subscription.id} className="card" style={{ margin: '.5em' }}>
                                <div className="card-body">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-md-auto my-auto" >
                                                <img src={"/app/" + subscription.appType + ".png"} className="" alt="..." />
                                            </div>
                                            <div className="col-sm-1 my-auto" >
                                                <figure className="figure text-center">
                                                    <p style={{ marginBottom: "0px", marginTop: "10px" }}>{subscription.status}</p>
                                                    <figcaption className="figure-caption">Since 2021</figcaption>
                                                </figure>
                                            </div>
                                            <div className="col-sm-1 my-auto" >
                                                <figure className="figure text-center">
                                                    <p style={{ marginBottom: "0px", marginTop: "10px" }}>Licenses</p>
                                                    <figcaption className="figure-caption">{subscription.licenses}</figcaption>
                                                </figure>
                                            </div>
                                            <div className="col-sm-1 my-auto" >
                                                <figure className="figure text-center">
                                                    <p style={{ marginBottom: "0px", marginTop: "10px" }}>RDP Users</p>
                                                    <figcaption className="figure-caption">{subscription.eAutomate.rdpLicenses}</figcaption>
                                                </figure>
                                            </div>
                                            <div className="col-sm-1 my-auto" >
                                                <figure className="figure text-center">
                                                    <p style={{ marginBottom: "0px", marginTop: "10px" }}>Cloud</p>
                                                    <figcaption className="figure-caption">{subscription.eAutomate.cloud.trim() === '' ? "No" : subscription.eAutomate.cloud}</figcaption>
                                                </figure>
                                            </div>
                                            <div className="col-sm-auto" >
                                                <div className="vr" style={{ height: "100%" }}></div>
                                            </div>
                                            <div className="col-lg-4 my-auto">
                                                {/* If we add more than one action here, we should use a bootstrap button group.
                                                    see this for more info: https://getbootstrap.com/docs/5.0/components/button-group/
                                                */}
                                                {isNullOrWhiteSpace(subscription.accessToken) ?
                                                    <div>
                                                        {this.state.accessTokenLoading ?
                                                            <div className="d-flex justify-content-center mb-0">
                                                                <Loader></Loader>
                                                            </div>
                                                        :
                                                            <button type="button" className="btn btn-outline-primary" onClick={() => { this.generateAccessToken(subscription) }}>Generate Access token</button>
                                                        }
                                                    </div>
                                                    :
                                                    <CopyToClipboard text={subscription.accessToken}
                                                        onCopy={() => this.toast()}>
                                                        <div className="input-group mb-0">
                                                            <input type="text" className="form-control" id="accessToken" aria-label="Generated Access Token" readOnly value={subscription.accessToken} />
                                                            <div className="input-group-append">
                                                                <button className="btn btn-outline-secondary" type="button" >Copy</button>
                                                            </div>
                                                        </div>
                                                    </CopyToClipboard>
                                                }
                                            </div>
                                            <div className="col-sm-auto my-auto ">
                                                <div style={{ float: "right" }}>
                                                    {subscription.status === "Active" ?
                                                        <svg width="20px" height="20px" viewBox="0 0 2 2">
                                                            <circle cx="1" cy="1" r="1" fill="#00CC1B">
                                                                <title>Subscription Active</title>
                                                            </circle>
                                                        </svg>
                                                        :
                                                        <svg width="20px" height="20px" viewBox="0 0 2 2">
                                                            <circle cx="1" cy="1" r="1" fill="#EF3320">
                                                                <title>Subscription Inactive</title>
                                                            </circle>
                                                        </svg>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                );
            }
        }
    }
    

    render() {
        let customerSubscriptions = this.renderCustomerSubscriptions();
        return (
            <div>
                {customerSubscriptions}
                <div className="position-fixed bottom-0 end-0 p-3" style={{ zIndex: '11' }}>
                    <div id="liveToast" className="toast hide" role="alert" aria-live="assertive" aria-atomic="true">
                        <div className="toast-header">
                            <strong className="me-auto">Information</strong>
                                <small>copied</small>
                            <button type="button" className="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
                        </div>
                        <div className="toast-body">
                            Your access token as been copied to your clipboard.
                        </div>
                    </div>
                </div>
            </div>
        );
    }



}
export default ViewSubscriptions;