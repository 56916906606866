import axios from "axios";
import Loader from "./Loader";
import { Link } from "react-router-dom";
import React, { Component } from 'react';
import DataTable from "react-data-table-component";
export class GetAllCustomers extends Component {
    static displayName = GetAllCustomers.name;
    constructor(props) {
        super(props);
        this.state = {
            customers: [],
            customer: null,
            loading: true,
            error: false,
            errorMessage: "",
            tableRowsData: this.populateCustomerData,
            columns: [
                {
                    name: "Name",
                    selector: row => <Link to={"/Customer/" + row.customerId}>{row.accountName}</Link>,
                    sortable: true,
                },
                {
                    name: "Netsuite ID",
                    selector: row => row.netsuiteId,
                    sortable: true,
                },
                {
                    name: "Salesforce ID",
                    selector: row => row.salesforceId,
                    sortable: true,
                },
            ],
        };
    }

    componentDidMount() {
        this.populateCustomerData();
    }

    onChange = async (e) => {
        var searchData = this.state.customers.filter((customer) => {
            if (
                customer.accountName
                    .toString()
                    .toLowerCase()
                    .includes(e.target.value.toLowerCase())
            ) {
                return customer;
            }
        });
        if (searchData.length === 0) {
            this.setState({ tableRowsData: this.state.customers })
        }
        else { this.setState({ tableRowsData: searchData }); }
        
    };

    renderCustomersTable(customers) {
        const ExpandedComponent = ({ data }) => {
            return Object.entries(data).map(([key, value]) => <pre>{key}: {value}</pre>);
        }
        return (
            <DataTable
                columns={this.state.columns}
                data={customers}
                expandableRows
                expandableRowsComponent={ExpandedComponent}
                pagination
            />
        );
    }

    render() {
        if (this.state.loading) {
            return (<Loader message="Loading customers please wait.."></Loader>)
        }
        else {
            if (this.state.error) {
                return (
                    <div className="alert alert-danger" role="alert">
                        Error occurred while loading customers. {this.state.errorMessage}
                    </div>
                )
            }
            else {
                return (
                    <>
                        <br />
                        <h1 id="tableLabel">Customers Table</h1>
                        <br />
                        <input
                            type="text"
                            onChange={this.onChange}
                            placeholder="Search by Name"
                            className="form-control"
                            style={{ width: "30%" }}
                        />
                        <br />
                        {this.renderCustomersTable(this.state.tableRowsData)}
                        <br />
                    </>
                )
            }
        }
    };

    async populateCustomerData() {
        await axios.get('customers')
            .then(data => {
                // handle successful response data
                this.setState({
                    customers: data.data,
                    tableRowsData: data.data,
                    loading: false,
                    error: false
                })
            })
            .catch(error => {
                // handle any error that occurred during the POST
                // extract proper error message
                let errorMessage = "Unknown error occurred.";
                if (error) {
                    console.error(error);
                    if (error.response) {
                        // handle successful response data
                        errorMessage = error.response.data.message ? error.response.data.message : error.message;
                    }
                    else {
                        errorMessage = error.message;
                    }
                }
                // set state
                this.setState({
                    errorMessage: errorMessage,
                    loading: false,
                    error: true
                })
            }
        );
    }
}

export default GetAllCustomers;