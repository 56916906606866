import { BoxArrowUpRight } from 'react-bootstrap-icons';


const ExternalLink = ({ href, title, children }) => {
  title = title || `Open ${href} in new tab`;

  return (
    <>
      <a href={href} target="_blank" rel="noreferrer" title={title}>
        {children}
        <BoxArrowUpRight className='mx-1' size={12} />
      </a> 
    </>
  );
};

export default ExternalLink;