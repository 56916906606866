/// <reference path="../reportwebvitals.js" />
import axios from "axios";
import Loader from "./Loader";
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Collapse, CardBody, Card, CardHeader, Button } from 'reactstrap';
import { useParams } from "react-router-dom";
function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

export class GetSubscription extends Component {
    static displayName = GetSubscription.name;
    constructor(props) {
        super(props);
        let { id } = this.props.params;
        this.state = { id: id, details: false, loading: true, error: false, errorMessage: "", shouldDirect: false };
    }

    componentDidMount() {
        this.populateSubscriptionData(this.state.id);
    }

    toggle = () => {
        this.setState({ details: !this.state.details });
    }

    getLoginLink(customerId) {
        const rootHostname = window.location.host == 'localhost' ? 'dev.eci.services' : window.location.host;
        return `https://portal.${rootHostname}/?customerId=${customerId}`;
    }

    renderSubscriptionCard = () => {
        if (this.state && this.state.subscription) {
            let subscription = this.state.subscription

            return (
                <>
                    <Card>
                        <CardHeader>
                            <table className='table' aria-labelledby="tableLabel">
                                <tbody>
                                    <tr><td>ID</td>                     <td>{subscription.id}</td></tr>
                                    <tr><td>Customer ID</td>            <td><Link to={"/Customer/" + subscription.customerId}>{subscription.customerId}</Link></td></tr>
                                </tbody>
                            </table>
                        </CardHeader>
                        <CardBody>
                            <a href={this.getLoginLink(subscription.customerId)}>
                                <Button color="primary">Login</Button>
                            </a>
                        </CardBody>
                    </Card>
                    <Card>
                        <CardHeader>
                            <div style={{ cursor: 'pointer' }} onClick={this.toggle}>Subscription details {this.state.details === true ? "(hide)" : "(show more)"}</div>
                        </CardHeader>
                        <Collapse isOpen={this.state ? this.state.details : false}>
                            <CardBody>
                                <table className='table table-striped' aria-labelledby="tableLabel">
                                    <tr><td>Net Suite ID</td>           <td>{subscription.netSuiteId}</td></tr>
                                    <tr><td>SKU</td>                    <td>{subscription.sku}</td></tr>
                                    <tr><td>Expiry Date</td>            <td>{subscription.expiryDate}</td></tr>
                                    <tr><td>AppType</td>                <td>{subscription.appType}</td></tr>
                                    <tr><td>Licenses</td>               <td>{subscription.licenses}</td></tr>
                                    <tr><td>Status</td>                 <td>{subscription.status}</td></tr>
                                </table>
                            </CardBody>
                        </Collapse>
                    </Card>
                </>
            );
        }
    }

    render() {
        let contents = this.state.loading
            ? <Loader message="Loading subscription please wait.."></Loader>
            : this.renderSubscriptionCard();

        return (
            <div>
                {!this.state.loading && this.state.error ?
                    <div className="alert alert-danger" role="alert">
                        Error occurred while loading subscription. {this.state.errorMessage}
                    </div> : null}
                <br />
                <h1 id="tableLabel">{this.state && this.state.subscription ? this.state.subscription.accountName : "Subscription"}</h1>
                {contents}
            </div>
        );
    }

    async populateSubscriptionData(id) {
        await axios.get(`subscription/${id}`)
            .then(data => {
                // handle successful response data
                this.setState({
                    details: false,
                    subscription: data.data,
                    loading: false,
                    error: false
                })
            })
            .catch(error => {
                // handle any error that occurred during the POST
                // extract proper error message
                let errorMessage = "Unknown error occurred.";
                if (error) {
                    console.error(error);
                    if (error.response) {
                        // handle successful response data
                        errorMessage = error.response.data.message ? error.response.data.message : error.message;
                    }
                    else {
                        errorMessage = error.message;
                    }
                }
                // set state
                this.setState({
                    errorMessage: errorMessage,
                    loading: false,
                    error: true
                })
            });
    }
}
export default withParams(GetSubscription);