/// <reference path="../reportwebvitals.js" />
import axios from "axios";
import Loader from "./Loader";
import React, { Component } from 'react';
import { Collapse, CardBody, Card, CardHeader, Button } from 'reactstrap';
import { useParams, Link } from "react-router-dom";
import ExternalLink from './ExternalLink';
import ViewSubscriptions from './ViewSubscriptions';
function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

export class GetCustomer extends Component {
    static displayName = GetCustomer.name;
    constructor(props) {
        super(props);
        let { customerId } = this.props.params;
        this.state = { customerId: customerId, details: false, loading: true, error: false, errorMessage: "", shouldDirect: false };
    }

    componentDidMount() {
        this.populateCustomerData(this.state.customerId);
    }

    toggle = () => {
        this.setState({ details: !this.state.details });
    }

    getLoginLink(customerId) {
        const rootHostname = window.location.host == 'localhost' ? 'dev.eci.services' : window.location.host;
        return `https://portal.${rootHostname}/?customerId=${customerId}`;
    }

    renderCustomerCard = () => {
        if (this.state && this.state.customer) {
            let customer = this.state.customer
            return (
                <>
                    <Card>
                        <CardHeader>
                            <table className='table' aria-labelledby="tableLabel"><tbody>
                                <tr><td>Account Name</td>           <td>{customer.accountName}</td></tr>
                                <tr><td>CIAM Customer ID</td>       <td>{customer.customerId}</td></tr>
                                <tr><td>Nexus ID</td>               <td>{customer.nexusId ? ( <ExternalLink href={`https://manage.ecinexus.com/customers/view/${customer.nexusId}`} title="Open in Nexus (new tab)">{customer.nexusId}</ExternalLink> ) : ( <></> )}</td></tr>
                                <tr><td>Salesforce ID</td>          <td>{customer.salesforceId ? ( <ExternalLink href={`https://ecisolutions.lightning.force.com/lightning/r/Account/${customer.salesforceId}/view`} title="Open in Salesforce (new tab)">{customer.salesforceId}</ExternalLink> ) : ( <></> )}</td></tr>
                                <tr><td>Netsuite ID</td>            <td>{customer.netsuiteId}</td></tr>
                            </tbody></table>
                        </CardHeader>
                        <CardBody>
                            <a href={this.getLoginLink(customer.customerId)} target="_blank"><Button color="primary">Customer Portal Login</Button></a>
                            <h3>Subscriptions</h3>
                            <ViewSubscriptions customer={customer}></ViewSubscriptions>
                        </CardBody>
                    </Card>
                    <Card>
                        <CardHeader>
                            <div style={{ cursor: 'pointer' }} onClick={this.toggle}>Customer details {this.state.details === true ? "(hide)" : "(show more)" }</div>
                        </CardHeader>
                        <Collapse isOpen={this.state ? this.state.details : false}>
                            <CardBody>
                                <table className='table table-striped' aria-labelledby="tableLabel"><tbody>
                                    <tr><td>Last Sync from Nexus</td>   <td>{customer.lastSyncFromNexus}</td></tr>
                                    <tr><td>Last Modified</td>          <td>{customer.nexusModified}</td></tr>
                                    <tr><td>Created</td>                <td>{customer.nexusCreated}</td></tr>
                                </tbody></table>
                            </CardBody>
                        </Collapse>
                    </Card>
                </>
            );
        }
    }
  
    render() {
        let contents = this.state.loading
            ? <Loader message="Loading customer please wait.."></Loader>
            : this.renderCustomerCard();

         return (
            <div>
                {!this.state.loading && this.state.error ?
                    <div className="alert alert-danger" role="alert">
                        Error occurred while loading customer. {this.state.errorMessage}
                    </div> : null}
                 <br />
                 <h1 id="tableLabel">{this.state && this.state.customer ? this.state.customer.accountName : "Customer"}</h1>
                 { contents }
             </div>
        );
    }
 
    async populateCustomerData(customerId) {
        await axios.get(`customer/${customerId}`)
            .then(data => {
                // handle successful response data
                this.setState({
                    details: false,
                    customer: data.data,
                    loading: false,
                    error: false
                })
            })
            .catch(error => {
                // handle any error that occurred during the POST
                // extract proper error message
                let errorMessage = "Unknown error occurred.";
                if (error) {
                    console.error(error);
                    if (error.response) {
                        // handle successful response data
                        errorMessage = error.response.data.message ? error.response.data.message : error.message;
                    }
                    else {
                        errorMessage = error.message;
                    }
                }
                // set state
                this.setState({
                    errorMessage: errorMessage,
                    loading: false,
                    error: true
                })
            });
    }
}
export default withParams(GetCustomer);